import type { Request } from 'express'
import type { Types } from 'mongoose'

import type { UserFeature } from '../userFeatures'

export interface IUser {
  _id: Types.ObjectId
  email: string
  password: string
  passwordToken?: string
  role: UserRole
  lastLoggedIn?: Date
  created: Date
  changed: Date
}

export type UserFormatted = Omit<IUser, '_id' | 'changed' | 'created' | 'lastLoggedIn' | 'password'> & {
  id: string
  userFeatures?: UserFeature[]
  lastLoggedIn?: string
}

export enum UserRole {
  ADMIN = 'ADMIN'
}

export interface RequestAuthenticated extends Request {
  user?: IUser
}

export interface IDeck {
  _id: Types.ObjectId
  name: string
  slug: string
  image?: string
  data: string
  isVisible: boolean
  creator: IUser
  created: Date
  changed: Date
}

export type DeckFormatted = Omit<IDeck, '_id' | 'changed' | 'created' | 'creator'> & {
  id: string
  changed: string
  created: string
  creator: UserFormatted
}

export interface IMedia {
  _id: Types.ObjectId
  name: string
  mimeType: string
  bucketName: string
  fileBucketLocation: string
  bucketPath: string
  creator: IUser
  created: Date
  changed: Date
}

export type MediaFormatted = Omit<IMedia, '_id' | 'changed' | 'created' | 'creator' | 'fileBucketLocation' | 'bucketName' | 'bucketPath'> & {
  id: string
  changed: string
  created: string
  url: string
  creator: UserFormatted
}

export enum ProjectStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED'
}

export interface IProject {
  _id: Types.ObjectId
  name: string
  disableModalStart?: boolean
  passwords?: string[]
  status: ProjectStatus
  theme: string
  overview: string
  slug: string
  decks: IDeck[]
  media: IMedia[]
  creator: IUser
  currentEditor?: IUser
  lastPublishDate: Date
  created: Date
  changed: Date
}

export type PlayerProjectFormatted = Omit<IProject, '_id' | 'name' | 'status' | 'passwords' | 'disableModalStart' | 'slug' | 'overview' | 'changed' | 'created' | 'creator' | 'currentEditor' | 'decks' | 'media' | 'lastPublishDate'> & {
  id: string
  media: MediaFormatted[]
}

export type ProjectFormatted = Omit<IProject, '_id' | 'changed' | 'created' | 'creator' | 'currentEditor' | 'decks' | 'media' | 'lastPublishDate'> & {
  id: string
  creator: UserFormatted
  currentEditor?: UserFormatted
  decks: DeckFormatted[]
  media: MediaFormatted[]
  changed: string
  created: string
  lastPublishDate?: string
}

export enum MediaRequestUploadType {
  PROJECT_MEDIA = 'PROJECT_MEDIA'
}

interface RequestUploadBody {
  fileName: string
  type: MediaRequestUploadType
  projectId?: string
}

export interface RequestUploadRequest extends RequestAuthenticated {
  body: RequestUploadBody
}

export interface MediaCreateBody {
  name: string
  mimeType: string
  bucketName: string
  bucketPath: string
  fileBucketLocation: string
}

export interface MediaDeleteBody {
  mediaIds: string[]
}

export enum EventType {
  PROJECT_DOWNLOAD_FILE = 'PROJECT_DOWNLOAD_FILE',
  DECK_START = 'DECK_START',
  DECK_FINISH = 'DECK_FINISH',
  DECK_CLOSE = 'DECK_CLOSE',
  DECK_PREVIEW = 'DECK_PREVIEW',
  DECK_CHECKOUT = 'DECK_CHECKOUT'
}

/*
  Enum specifically used for statistics exports.
  Includes enum entries for unique statistics.
 */
export enum EventExportType {
  DECK_PREVIEW_UNIQUE = 'DECK_PREVIEW_UNIQUE',
  DECK_START_UNIQUE = 'DECK_START_UNIQUE',
  DECK_FINISH_UNIQUE = 'DECK_FINISH_UNIQUE'
}

export type EventFormatted = Omit<IEvent, '_id' | 'created' | 'project' | 'deck'> & {
  id: string
  created: string
  project?: ProjectFormatted
  deck?: DeckFormatted
}

export interface IEvent {
  _id: Types.ObjectId
  type: EventType
  project?: IProject
  deck?: IDeck
  sessionId?: string
  meta?: string
  created: Date
}

export interface IEventCreateBody {
  type: EventType
  deckId?: string
  sessionId?: string
  projectId?: string
  meta?: string | {}
}

export enum RoomEvents {
  ROOM_UPDATED = 'roomUpdated',
  ROOM_PARTICIPANT_UPDATED = 'roomParticipantUpdated',
  LAST_INTERACTION_UPDATED = 'lastInteractionUpdated'
}

export enum CheckoutInteractionKey {
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  POSITIVE = 'positive'
}

export interface StatisticsMetaProjectCheckoutsInteraction {
  id: string
  label: string
  answers: Record<CheckoutInteractionKey, number>
}

export interface StatisticsMetaProjectCheckouts {
  roomId?: string
  interactions: StatisticsMetaProjectCheckoutsInteraction[]
}

export interface StatisticsMetaProjectDownloads {
  fileName: string
}

export type StatisticsMeta = StatisticsMetaProjectCheckouts

export enum StatisticsType {
  PROJECT_DECK_USAGE = 'PROJECT_DECK_USAGE',
  PROJECT_CHECKOUTS = 'PROJECT_CHECKOUTS',
  PROJECT_DOWNLOADS = 'PROJECT_DOWNLOADS'
}
